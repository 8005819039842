import { transformColumnsI18n } from '@/language';
const columns = [{
        title: 'goodsManage.table.pic',
        dataIndex: 'pic',
        key: 'pic',
        width: 60,
        slots: { customRender: 'pic' },
    },
    {
        title: 'goodsManage.table.spu',
        dataIndex: 'spu',
        key: 'spu',
        width: 120,
        slots: { customRender: 'spu' },
    },
    {
        title: 'goodsManage.table.colorName',
        dataIndex: 'colorName',
        key: 'colorName',
        width: 80,
        slots: { customRender: 'colorName' },
    },
    {
        title: 'goodsManage.table.sizes',
        dataIndex: 'sizes',
        key: 'sizes',
        width: 100,
        slots: { customRender: 'sizes' },
    },
    {
        title: 'goodsManage.table.price',
        dataIndex: 'price',
        key: 'price',
        width: 60,
        slots: { customRender: 'price' },
    },
    {
        title: 'goodsManage.table.storage',
        dataIndex: 'storage',
        key: 'storage',
        width: 60,
        slots: { customRender: 'storage' },
    },

    {
        title: 'goodsManage.table.productName',
        dataIndex: 'productName',
        key: 'productName',
        width: 240,
        slots: { customRender: 'productName' },
    },
    // {
    //     title: 'goodsManage.table.operate', //operate
    //     key: 'operate',
    //     fixed: 'right',
    //     align: 'center',
    //     width: 150,
    //     slots: { customRender: 'operate' },
    // },
];
export function exportTable(index) {
    let tableObj = JSON.parse(JSON.stringify(columns));
    //1-上架中  2-待上架  3-已下架  4-全部
    if (index == '1') {
        let obj = {
            title: 'goodsManage.table.hh',
            dataIndex: 'hh',
            key: 'hh',
            width: 100,
            slots: { customRender: 'hh' },
        };
        // tableObj.splice(-2, 0, obj);
        tableObj.splice(-1, 0, obj);
    }
    if (index == '2') {
        let obj = {
            title: 'goodsManage.table.createAt',
            dataIndex: 'createAt',
            key: 'createAt',
            width: 100,
        };
        // tableObj.splice(-2, 0, obj);
        tableObj.splice(-1, 0, obj);
        // tableObj.splice(-1, 1);
    }
    if (index == '3') {
        let [obj1] = [
            {
                title: 'goodsManage.table.ll',
                dataIndex: 'll',
                key: 'll',
                width: 100,
                slots: { customRender: 'll' },
            },
        ];
        // tableObj.splice(-2, 0, obj1);
        tableObj.splice(-1, 0, obj1);
    }
    if (index == '4') {
        let obj = {
            title: 'goodsManage.table.state',
            dataIndex: 'state',
            key: 'state',
            width: 80,
            slots: { customRender: 'state' },
        };
        // tableObj.splice(-2, 0, obj);
        tableObj.splice(-1, 0, obj);
    }
    return transformColumnsI18n(tableObj);
}
