<template>
  <div class="centerView">
    <a-tabs v-model:activeKey="activeKey" @change="tabsChange" size="small">
      <a-tab-pane :key="item.key" :tab="t(item.title) + `(${item.num})`" v-for="item in tabList"></a-tab-pane>
    </a-tabs>
    <a-table v-if="!tableLoading" :dataSource="tableData" :columns="columns" :pagination="false" size="small" :row-key="r => r.id" :scroll="{ x: 1300 }">
      <template #pic="{ record }">
        <ImgGallery :urls="[record.picTemplate]" :img-width="39" :img-height="52" :show-img-num="1" :show-more="false" />
      </template>
      <template #spu="{ record, text }">
        <a-space direction="vertical">
          <span>{{ t('goodsManage.table.styleCode') }}：{{ record.styleCode || '-' }}</span>
          <span>SPU：{{ text || '-' }}</span>
        </a-space>
      </template>
      <template #sizes="{ text }">
        {{ text.join('，') || '-' }}
      </template>
      <template #colorName="{ text }">
        {{ text || '-' }}
      </template>
      <template #price="{ record }">
        ￥
        <span>{{ record.goodsBo ? record.goodsBo.originalFactoryPrice : '0' }}</span>
      </template>
      <template #storage="{ text }">
        <span>{{ text || '0' }}</span>
      </template>
      <template #hh="{ text }">
        <span>{{ text || '-' }}</span>
      </template>
      <template #createAt="{ text }">
        <span>{{ text || '-' }}</span>
      </template>
      <template #ll="{ text }">
        <span>{{ text || '-' }}</span>
      </template>
      <template #productName="{ text }">
        <span>{{ text || '-' }}</span>
      </template>
      <!-- 操作 -->
      <template #state="{ text }">
        <span :style="{ color: text === 1 ? '#4FD538' : text === 0 ? '#F53838' : '' }">
          {{ ([null, 0, 1, 9, 99].includes(text) && t(statusList.find(it => it.value === text).label)) || '-' }}
        </span>
      </template>
      <template #operate>
        <a-button type="primary" class="padding-horizontal-12" @click="operationLogFn(text)">{{ t('goodsManage.table.log') }}</a-button>
      </template>
    </a-table>
    <TableSkeleton v-if="tableLoading" />
    <pagination mt lable="" :data="list" @change="onPageChange" @sizeChange="onSizeChange"></pagination>

    <!-- 日志 -->
    <OperationLogModal v-model:modalVisible="operationLogModalVisible" />
  </div>
</template>
<script setup>
  /* eslint-disable */
  import { ref, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import pagination from '@/components/pagination.vue'
  import TableSkeleton from '@/views/supplier/components/TableSkeleton'
  import OperationLogModal from '@/views/supplier/components/OperationLogModal'
  import ImgGallery from '@/views/supplier/components/ImgGallery'
  import { exportTable } from '../config/goodsTableConfig'

  import { getGoodsManagementTableData, getGoodsManagementTotalsData } from '@/api/supplier/goodsManagement'

  const { t, locale } = useI18n()
  const router = useRouter()

  //tab绑定
  const activeKey = ref('1')
  //tablist
  // 0 已下架，1：已上架  ，9：待上架，99 已归档，不传 表示全部
  const tabList = ref([
    { key: '1', title: 'goodsManage.tabsPane.1', num: 0, index: 1, flag: 'put' },
    { key: '2', title: 'goodsManage.tabsPane.2', num: 0, index: 9, flag: 'waitPut' },
    { key: '3', title: 'goodsManage.tabsPane.3', num: 0, index: 0, flag: 'pull' },
    { key: '4', title: 'goodsManage.tabsPane.4', num: 0, index: null, flag: 'all' }
  ])

  const statusList = [
    {
      value: null,
      label: 'goodsManage.tabsPane.4'
    },
    {
      value: 0,
      label: 'goodsManage.tabsPane.3'
    },
    {
      value: 1,
      label: 'goodsManage.tabsPane.1'
    },
    {
      value: 9,
      label: 'goodsManage.tabsPane.2'
    },
    {
      value: 99,
      label: 'goodsManage.tabsPane.5'
    }
  ]

  function getGoodsManagementTotalsDataFn() {
    getGoodsManagementTotalsData({})
      .then(res => {
        if (res.code === 200) {
          tabList.value = tabList.value.map(item => {
            item.num = res.data[item.flag] || 0
            return item
          })
        } else {
          // message.error(res.msg)
        }
      })
      .catch(() => {})
  }
  // getGoodsManagementTotalsDataFn();

  //表格绑定标题
  const columns = ref([])
  columns.value = exportTable(activeKey.value)

  //表格数据
  const tableData = ref([])
  //表格分页
  const list = ref()
  const formData = ref({})
  //分页数据
  const page = ref({
    page: 1,
    size: 10
  })
  const tableLoading = ref(false)

  function action(payload) {
    console.log(payload, 'payload---payload')
    formData.value = payload
    getTableData()
  }

  defineExpose({
    action
  })

  function getTableData() {
    tableLoading.value = true

    getGoodsManagementTotalsDataFn()

    list.value = {}
    tableData.value = []
    let state = tabList.value.find(it => it.key == activeKey.value).index
    getGoodsManagementTableData({ ...formData.value, ...page.value, state })
      .then(res => {
        tableLoading.value = false
        if (res.code === 200) {
          list.value = res.data
          tableData.value = list.value.data
          // tabList.value.find((it) => it.key == activeKey.value).num = res.data.total;
        } else {
          // message.error(res.msg)
        }
      })
      .catch(() => {
        tableLoading.value = false
      })
  }
  getTableData()

  //表格分页事件
  const onPageChange = data => {
    page.value.page = data
    getTableData()
  }

  //表格每页条数事件
  const onSizeChange = data => {
    page.value.size = data.pageSize
    page.value.page = 1
    getTableData()
  }

  //tabs切换页面
  const tabsChange = e => {
    columns.value = exportTable(activeKey.value)
    getTableData()
  }

  watch(locale, () => {
    columns.value = exportTable(activeKey.value)
  })

  /* 操作日志 */
  const operationLogModalVisible = ref(false)
  /* 操作日志 */
  function operationLogFn() {
    operationLogModalVisible.value = true
  }
</script>
<style lang="less" scoped></style>
